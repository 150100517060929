import React, { useState } from "react";
import axios from "axios";
import "./style.css"

const InsertTodos = () => {
    const [state, setState] = useState({
      id: "",
      todo: ""
    });
  
    const handleChange = (e) => {
      const value = e.target.value;
      setState({
        ...state,
        [e.target.name]: value // Ändere "id" zu "name" hier
      });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      if(state.id && state.todo) {
          axios.get(`http://217.160.57.108:5000/insertTodos?id=${state.id}&todo=${state.todo}`).then((response) => {
            console.log(response.status, response.data);
          });
      } else {
        console.log("gehfehler")
      }
      Promise(window.location.reload());
    };
  
    return (
      <div className="insertTodo">
        <h1>Erstelle eine neue Todo</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="id">
            id
            <br/>
            <input
              type="text"
              name="id" // Ändere "id" zu "name" hier
              value={state.id}
              onChange={handleChange}
            />
          </label>
          <label htmlFor="todo">
            todo
            <br/>
            <input
              type="text"
              name="todo" // Ändere "id" zu "name" hier
              value={state.todo}
              onChange={handleChange}
            />
          </label>
          <button type="submit" onClick={handleSubmit}>Hinzufügen</button>
        </form>
      </div>
    );
  };
  

export {
    InsertTodos
}