import './App.css';
import { GetTodos } from "./components/getTodos/getTodos.js"
import { InsertTodos } from "./components/insertTodos/insertTodos.js"
import { HeaderList, HeaderAdd } from "./components/pages/header/header.js"
import { DeleteTodos } from './components/deleteTodos/deleteTodos.js';
import { UpdateTodos } from './components/updateTodos/updateTodos';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

function App() {
  return (
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<><GetTodos/><InsertTodos/><DeleteTodos/><UpdateTodos/></>}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;