import React, { useState, useEffect } from "react";
import axios from "axios";

function GetTodos() {
    const [todo, setTodo] = useState([]);

    useEffect(() => {
        // fetch(`/getTodos`).then((res) => res.json()).then((data) => {
        //   setTodo(data)
        // })
        axios.get("http://217.160.57.108:5000/getTodos")
            .then(res => {
                setTodo(res.data);
            });
        

    }, []);

    return (
        <div className="App">
          <h1>Gretis und Leons Todoapp</h1>
          {todo.map(item => (
            <div key={item.id}>
            {item.id}: {item.todo}
          </div>
          ))}
          {/* {JSON.stringify(todo)} */}
        </div>
    );
}
export {
    GetTodos
}