import React, { useState } from "react";
import axios from "axios";
import "./style.css"

function DeleteTodos() {
    const [state, setState] = useState({
        id: ""
      });
    
      const handleChange = (e) => {
        const value = e.target.value;
        setState({
          ...state,
          [e.target.name]: value // Ändere "id" zu "name" hier
        });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        axios.get(`http://217.160.57.108:5000/deleteTodos?id=${state.id}`).then((response) => {
          console.log(response.status, response.data);
        });
        Promise(window.location.reload());
      };
    
      return (
        <div className="deleteTodo">
          <h1>Lösche Todos anhand der Id</h1>
          <form onSubmit={handleSubmit}>
            <label htmlFor="id">
              Id
              <br/>
              <input
                type="text"
                name="id" // Ändere "id" zu "name" hier
                value={state.id}
                onChange={handleChange}
              />
            </label>
            <button type="submit">Löschen</button>
          </form>
        </div>
      );
    };

export {
    DeleteTodos
}